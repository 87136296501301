import "../App.css";
import React from 'react'
import ReactBubblyEffectButton from "react-bubbly-effect-button";

const onClick = () => {
  console.log('Clicked')
}

const BubblyButton = () => {
  return <div className='container'>
    <ReactBubblyEffectButton text="Click here" color='#fff' bgColor='#68DBAB' onClick={onClick} />
  </div>;
}

export default BubblyButton;