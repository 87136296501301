
const API_URL = process.env.REACT_APP_API_HOSTNAME;

export default {
    postForm1: (body) => new Promise((resolve) => {
        const url = `${API_URL}/app/generate`
        const requestHeaders = new Headers()
        requestHeaders.set('Content-Type', 'application/json')
        const options = { headers: requestHeaders }
        options.method = 'POST'
        options.body = JSON.stringify(body)
        fetch(url, options).then(res => {
            return res.json()
        }).then((json) => {
            resolve(json)
        })
    }),
    suggest: (q) => new Promise((resolve) => {
        const url = `${API_URL}/app/suggest?q=${encodeURIComponent(q)}`
        fetch(url).then(res => {
            return res.json()
        }).then((json) => {
            resolve(json.data)
        })
    }),
    find: (siren) => new Promise((resolve) => {
        const url = `${API_URL}/app/find?siren=${encodeURIComponent(siren)}`
        fetch(url).then(res => {
            return res.json()
        }).then((json) => {
            resolve(json)
        })
    }),

}