import React, { Component } from 'react';

import { MultiStepForm, Step } from 'react-multi-form';

import Api from '../api/Api'

import Desktop from '../responsive/Desktop2'
import Mobile from '../responsive/Mobile2'

import { TextField, InputLabel, Select, MenuItem, FormControl, Button } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import ReactSimpleOptionsSelector from "react-simple-options-selector"
import Lottie from 'react-lottie-player'

import styled from 'styled-components';

import Autocomplete2 from 'react-autocomplete';
import Toggle from 'react-toggle'

import ReactBubblyEffectButton from "react-bubbly-effect-button";

import Confetti from 'react-confetti'

import '../scss/common.scss';
import '../scss/home.scss';
import "react-toggle/style.css"

import lottieJson from './my-lottie.json'
import firstnames from './firstnames.json'
import lastnames from './lastnames.json'
import nums from './nums.json'
import locations from './locations.json'


const StyledInput = styled.input`
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 8px; 
  font-size: 0.95rem;
  outline: none;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  &:focus {
    border: 1.5px solid #68DBAB;
  }
`

const StyledLabel = styled.label`
  font-size: 0.9rem;
  float: left;
`

const StyledInput2 = styled.input`
  height: 11px;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 8px; 
  font-size: 0.95rem;
  outline: none;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  &:focus {
    border: 1.5px solid #68DBAB;
  }
`

const StyledLabel2 = styled.label`
  font-size: 0.9rem;
`

function Input(props) {

  return (
    <div>
      <StyledLabel>{props.label}</StyledLabel>
      <StyledInput {...props} />
    </div>
  )
}

function LineInput(props) {

  return (
    <div>
      <StyledInput2 {...props} />
      <StyledLabel2>{props.label}</StyledLabel2>
    </div>
  )
}

const SelectStyle = styled.select`
  width: 100%;
  background: white;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 8px; 
  font-size: 0.95rem;
  outline: none;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  &:focus {
    border: 1.5px solid #68DBAB;
  }

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

function Select2(props) {

  return (
    <div>
      <StyledLabel>{props.label}</StyledLabel>
      <SelectStyle {...props} />
    </div>
  )
}

const mandatory1_1 = [
  "Vous êtes (choisissez : Particulier ou Société)",
  "Prénom",
  "Nom",
  "Adresse",
  "Code postal",
  "Ville",
  "Email",
  "Téléphone",
]
const mandatory1_2 = [
  "Vous êtes (choisissez : Particulier ou Société)",
  "Préfixe de la société (SAS, SARL etc..)",
  "Nom de la société",
  "Adresse de la société",
  "Code postal de la société",
  "Ville de la société",
  "Téléphone de la société",
  "Numéro de SIRET",
  "Num TVA Intracommunautaire",
  "Email de la société",
]
const mandatory2_1 = [
  "Prénom",
  "Nom",
  "Adresse",
  "Code postal",
  "Ville",
  "Email",
  "Téléphone",
]
const mandatory2_2 = [
  "Nom/Prénom du Propriétaire du site",
  "Adresse mail du Propriétaire du site",
  "Nom/Prénom du Directeur de Publication",
  "Adresse mail du Directeur de Publication",
  "Nom/Prénom du Webmaster",
  "Adresse mail du Webmaster",
  "Nom/Prénom Autre",
  "Adresse mail Autre",
]

const mandatory3 = [
  "Nom de l'hébergeur",
  "Capital Social de l'hébergeur",
  "Type de société de l'hébergeur",
  "RCS de l'hébergeur",
  "Code APE de l'hébergeur",
  "TVA de l'hébergeur",
  "Adresse de l'hébergeur",
]

const mandatory4 = [
  "URL de votre site",
  "Email où recevoir les mentions légales",
  "J'accepte de recevoir mes mentions légales par mail"
]

const simpleTextView = (self, label, disabled = false) => {
  // if (localStorage.getItem(label) && !self.state[label])
  //   self.setState({ [label]: localStorage.getItem(label) })
  return (
    <div
      style={{ flex: 1, margin: "10px" }}
    >
      <Input
        disabled={disabled}
        type={label.toLowerCase().includes('mail') ? 'email' : 'text'}
        label={`${label.split(' de la société').join('')}${![...mandatory1_1, ...mandatory1_2, ...mandatory2_2, ...mandatory3, ...mandatory4].includes(label) ? '' : ' *'}`}
        onChange={(e) => {
          self.setState({ [label]: e.target.value })
          // localStorage.setItem(label, e.target.value)
        }}
        style={self.state.missing && self.state.missing[label] ? { borderColor: 'red' } : {}}
        defaultValue={self.state[label] /*|| localStorage.getItem(label)*/ || ''}
        value={self.state[label] /*|| localStorage.getItem(label)*/ || ''}
      />
    </div>
  )
}

const simpleCheckbox = (self, label) => {
  // if (localStorage.getItem(label) && !self.state[label])
  //   self.setState({ [label]: localStorage.getItem(label) })
  return (
    <div
      style={{ flex: 1, margin: "10px", ...(self.state.missing && self.state.missing[label] ? { borderBottom: "1px solid red" } : {}) }}
    >
      <LineInput
        type="checkbox"
        label={`${label}${![...mandatory1_1, ...mandatory1_2, ...mandatory2_2, ...mandatory3, ...mandatory4].includes(label) ? '' : ' *'}`}
        onChange={(e) => {
          self.setState({ [label]: !self.state[label] })
          // localStorage.setItem(label, e.target.value)
        }}
        defaultValue={/*self.state[label] || localStorage.getItem(label) ||*/ false}
        value={self.state[label] /*|| localStorage.getItem(label)*/ || false}
      />
    </div>
  )
}

const simpleSelect = (self, label, values, onChange = () => { }) => {
  // if (localStorage.getItem(label) && !self.state[label])
  //   self.setState({[label]: localStorage.getItem(label) })
  return (
    <div
      style={{ flex: 1, margin: "10px" }}
    >
      <Select2
        label={`${label}${![...mandatory1_1, ...mandatory1_2, ...mandatory2_2, ...mandatory3, ...mandatory4].includes(label) ? '' : ' *'}`}
        defaultValue={/*localStorage.getItem(label) || */ ''}
        value={self.state[label] || '' /* localStorage.getItem(label) || '' */}
        onChange={(e) => {
          self.setState({ [label]: e.target.value }, () => onChange())
          // localStorage.setItem(label, e.target.value)
        }}
        style={self.state.missing && self.state.missing[label] ? { borderColor: 'red' } : {}}
      >
        {
          values.map(elem => <option value={elem}>{elem}</option>)
        }
      </Select2>
    </div>
  )
}

const render_categorieJuridiqueUniteLegale = {
  '0000': "Organisme de placement collectif en valeurs mobilières sans personnalité morale",
  '1000': "Entrepreneur individuel",
  '2110': "Indivision entre personnes physiques",
  '2120': "Indivision avec personne morale",
  '2210': "Société créée de fait entre personnes physiques",
  '2220': "Société créée de fait avec personne morale",
  '2310': "Société en participation entre personnes physiques",
  '2320': "Société en participation avec personne morale",
  '2385': "Société en participation de professions libérales",
  '2400': "Fiducie",
  '2700': "Paroisse hors zone concordataire",
  '2900': "Autre groupement de droit privé non doté de la personnalité morale",
  '3110': "Représentation ou agence commerciale d'état ou organisme public étranger immatriculé au RCS",
  '3120': "Société commerciale étrangère immatriculée au RCS",
  '3205': "Organisation internationale",
  '3210': "État, collectivité ou établissement public étranger",
  '3220': "Société étrangère non immatriculée au RCS",
  '3290': "Autre personne morale de droit étranger",
  '4110': "Établissement public national à caractère industriel ou commercial doté d'un comptable public",
  '4120': "Établissement public national à caractère industriel ou commercial non doté d'un comptable public",
  '4130': "Exploitant public",
  '4140': "Établissement public local à caractère industriel ou commercial",
  '4150': "Régie d'une collectivité locale à caractère industriel ou commercial",
  '4160': "Institution Banque de France",
  '5191': "Société de caution mutuelle",
  '5192': "Société coopérative de banque populaire",
  '5193': "Caisse de crédit maritime mutuel",
  '5194': "Caisse (fédérale) de crédit mutuel",
  '5195': "Association coopérative inscrite (droit local Alsace Moselle)",
  '5196': "Caisse d'épargne et de prévoyance à forme coopérative",
  '5202': "Société en nom collectif",
  '5203': "Société en nom collectif coopérative",
  '5306': "Société en commandite simple",
  '5307': "Société en commandite simple coopérative",
  '5308': "Société en commandite par actions",
  '5309': "Société en commandite par actions coopérative",
  '5310': "Société en libre partenariat (SLP)",
  '5370': "Société de Participations Financières de Profession Libérale Société en commandite par actions (SPFPL SCA)",
  '5385': "Société d'exercice libéral en commandite par actions",
  '5410': "SARL nationale",
  '5415': "SARL d'économie mixte",
  '5422': "SARL immobilière pour le commerce et l'industrie (SICOMI)",
  '5426': "SARL immobilière de gestion",
  '5430': "SARL d'aménagement foncier et d'équipement rural (SAFER)",
  '5431': "SARL mixte d'intérêt agricole (SMIA)",
  '5432': "SARL d'intérêt collectif agricole (SICA)",
  '5442': "SARL d'attribution",
  '5443': "SARL coopérative de construction",
  '5451': "SARL coopérative de consommation",
  '5453': "SARL coopérative artisanale",
  '5454': "SARL coopérative d'intérêt maritime",
  '5455': "SARL coopérative de transport",
  '5458': "SARL coopérative ouvrière de production (SCOP)",
  '5459': "SARL union de sociétés coopératives",
  '5460': "Autre SARL coopérative",
  '5470': "Société de Participations Financières de Profession Libérale Société à responsabilité limitée (SPFPL SARL)",
  '5485': "Société d'exercice libéral à responsabilité limitée",
  '5499': "Société à responsabilité limitée (sans autre indication)",
  '5505': "SA à participation ouvrière à conseil d'administration",
  '5510': "SA nationale à conseil d'administration",
  '5515': "SA d'économie mixte à conseil d'administration",
  '5520': "Fonds à forme sociétale à conseil d'administration",
  '5522': "SA immobilière pour le commerce et l'industrie (SICOMI) à conseil d'administration",
  '5525': "SA immobilière d'investissement à conseil d'administration",
  '5530': "SA d'aménagement foncier et d'équipement rural (SAFER) à conseil d'administration",
  '5531': "Société anonyme mixte d'intérêt agricole (SMIA) à conseil d'administration",
  '5532': "SA d'intérêt collectif agricole (SICA) à conseil d'administration",
  '5542': "SA d'attribution à conseil d'administration",
  '5543': "SA coopérative de construction à conseil d'administration",
  '5546': "SA de HLM à conseil d'administration",
  '5547': "SA coopérative de production de HLM à conseil d'administration",
  '5548': "SA de crédit immobilier à conseil d'administration",
  '5551': "SA coopérative de consommation à conseil d'administration",
  '5552': "SA coopérative de commerçants-détaillants à conseil d'administration",
  '5553': "SA coopérative artisanale à conseil d'administration",
  '5554': "SA coopérative (d'intérêt) maritime à conseil d'administration",
  '5555': "SA coopérative de transport à conseil d'administration",
  '5558': "SA coopérative ouvrière de production (SCOP) à conseil d'administration",
  '5559': "SA union de sociétés coopératives à conseil d'administration",
  '5560': "Autre SA coopérative à conseil d'administration",
  '5570': "Société de Participations Financières de Profession Libérale Société anonyme à conseil d'administration (SPFPL SA à conseil d'administration)",
  '5585': "Société d'exercice libéral à forme anonyme à conseil d'administration",
  '5599': "SA à conseil d'administration (s.a.i.)",
  '5605': "SA à participation ouvrière à directoire",
  '5610': "SA nationale à directoire",
  '5615': "SA d'économie mixte à directoire",
  '5620': "Fonds à forme sociétale à directoire",
  '5622': "SA immobilière pour le commerce et l'industrie (SICOMI) à directoire",
  '5625': "SA immobilière d'investissement à directoire",
  '5630': "Safer anonyme à directoire",
  '5631': "SA mixte d'intérêt agricole (SMIA)",
  '5632': "SA d'intérêt collectif agricole (SICA)",
  '5642': "SA d'attribution à directoire",
  '5643': "SA coopérative de construction à directoire",
  '5646': "SA de HLM à directoire",
  '5647': "Société coopérative de production de HLM anonyme à directoire",
  '5648': "SA de crédit immobilier à directoire",
  '5651': "SA coopérative de consommation à directoire",
  '5652': "SA coopérative de commerçants-détaillants à directoire",
  '5653': "SA coopérative artisanale à directoire",
  '5654': "SA coopérative d'intérêt maritime à directoire",
  '5655': "SA coopérative de transport à directoire",
  '5658': "SA coopérative ouvrière de production (SCOP) à directoire",
  '5659': "SA union de sociétés coopératives à directoire",
  '5660': "Autre SA coopérative à directoire",
  '5670': "Société de Participations Financières de Profession Libérale Société anonyme à Directoire (SPFPL SA à directoire)",
  '5685': "Société d'exercice libéral à forme anonyme à directoire",
  '5699': "SA à directoire (s.a.i.)",
  '5710': "SAS, société par actions simplifiée",
  '5770': "Société de Participations Financières de Profession Libérale Société par actions simplifiée (SPFPL SAS)",
  '5785': "Société d'exercice libéral par action simplifiée",
  '5800': "Société européenne",
  '6100': "Caisse d'Épargne et de Prévoyance",
  '6210': "Groupement européen d'intérêt économique (GEIE)",
  '6220': "Groupement d'intérêt économique (GIE)",
  '6316': "Coopérative d'utilisation de matériel agricole en commun (CUMA)",
  '6317': "Société coopérative agricole",
  '6318': "Union de sociétés coopératives agricoles",
  '6411': "Société d'assurance à forme mutuelle",
  '6511': "Sociétés Interprofessionnelles de Soins Ambulatoires ",
  '6521': "Société civile de placement collectif immobilier (SCPI)",
  '6532': "Société civile d'intérêt collectif agricole (SICA)",
  '6533': "Groupement agricole d'exploitation en commun (GAEC)",
  '6534': "Groupement foncier agricole",
  '6535': "Groupement agricole foncier",
  '6536': "Groupement forestier",
  '6537': "Groupement pastoral",
  '6538': "Groupement foncier et rural",
  '6539': "Société civile foncière",
  '6540': "Société civile immobilière",
  '6541': "Société civile immobilière de construction-vente",
  '6542': "Société civile d'attribution",
  '6543': "Société civile coopérative de construction",
  '6544': "Société civile immobilière d' accession progressive à la propriété",
  '6551': "Société civile coopérative de consommation",
  '6554': "Société civile coopérative d'intérêt maritime",
  '6558': "Société civile coopérative entre médecins",
  '6560': "Autre société civile coopérative",
  '6561': "SCP d'avocats",
  '6562': "SCP d'avocats aux conseils",
  '6563': "SCP d'avoués d'appel",
  '6564': "SCP d'huissiers",
  '6565': "SCP de notaires",
  '6566': "SCP de commissaires-priseurs",
  '6567': "SCP de greffiers de tribunal de commerce",
  '6568': "SCP de conseils juridiques",
  '6569': "SCP de commissaires aux comptes",
  '6571': "SCP de médecins",
  '6572': "SCP de dentistes",
  '6573': "SCP d'infirmiers",
  '6574': "SCP de masseurs-kinésithérapeutes",
  '6575': "SCP de directeurs de laboratoire d'analyse médicale",
  '6576': "SCP de vétérinaires",
  '6577': "SCP de géomètres experts",
  '6578': "SCP d'architectes",
  '6585': "Autre société civile professionnelle",
  '6589': "Société civile de moyens",
  '6595': "Caisse locale de crédit mutuel",
  '6596': "Caisse de crédit agricole mutuel",
  '6597': "Société civile d'exploitation agricole",
  '6598': "Exploitation agricole à responsabilité limitée",
  '6599': "Autre société civile",
  '6901': "Autre personne de droit privé inscrite au registre du commerce et des sociétés",
  '7111': "Autorité constitutionnelle",
  '7112': "Autorité administrative ou publique indépendante",
  '7113': "Ministère",
  '7120': "Service central d'un ministère",
  '7150': "Service du ministère de la Défense",
  '7160': "Service déconcentré à compétence nationale d'un ministère (hors Défense)",
  '7171': "Service déconcentré de l'État à compétence (inter) régionale",
  '7172': "Service déconcentré de l'État à compétence (inter) départementale",
  '7179': "(Autre) Service déconcentré de l'État à compétence territoriale",
  '7190': "Ecole nationale non dotée de la personnalité morale",
  '7210': "Commune et commune nouvelle",
  '7220': "Département",
  '7225': "Collectivité et territoire d'Outre Mer",
  '7229': "(Autre) Collectivité territoriale",
  '7230': "Région",
  '7312': "Commune associée et commune déléguée",
  '7313': "Section de commune",
  '7314': "Ensemble urbain",
  '7321': "Association syndicale autorisée",
  '7322': "Association foncière urbaine",
  '7323': "Association foncière de remembrement",
  '7331': "Établissement public local d'enseignement",
  '7340': "Pôle métropolitain",
  '7341': "Secteur de commune",
  '7342': "District urbain",
  '7343': "Communauté urbaine",
  '7344': "Métropole",
  '7345': "Syndicat intercommunal à vocation multiple (SIVOM)",
  '7346': "Communauté de communes",
  '7347': "Communauté de villes",
  '7348': "Communauté d'agglomération",
  '7349': "Autre établissement public local de coopération non spécialisé ou entente",
  '7351': "Institution interdépartementale ou entente",
  '7352': "Institution interrégionale ou entente",
  '7353': "Syndicat intercommunal à vocation unique (SIVU)",
  '7354': "Syndicat mixte fermé",
  '7355': "Syndicat mixte ouvert",
  '7356': "Commission syndicale pour la gestion des biens indivis des communes",
  '7357': "Pôle d'équilibre territorial et rural (PETR)",
  '7361': "Centre communal d'action sociale",
  '7362': "Caisse des écoles",
  '7363': "Caisse de crédit municipal",
  '7364': "Établissement d'hospitalisation",
  '7365': "Syndicat inter hospitalier",
  '7366': "Établissement public local social et médico-social",
  '7367': "Centre Intercommunal d'action sociale (CIAS)",
  '7371': "Office public d'habitation à loyer modéré (OPHLM)",
  '7372': "Service départemental d'incendie et de secours (SDIS)",
  '7373': "Établissement public local culturel",
  '7378': "Régie d'une collectivité locale à caractère administratif",
  '7379': "(Autre) Établissement public administratif local",
  '7381': "Organisme consulaire",
  '7382': "Établissement public national ayant fonction d'administration centrale",
  '7383': "Établissement public national à caractère scientifique culturel et professionnel",
  '7384': "Autre établissement public national d'enseignement",
  '7385': "Autre établissement public national administratif à compétence territoriale limitée",
  '7389': "Établissement public national à caractère administratif",
  '7410': "Groupement d'intérêt public (GIP)",
  '7430': "Établissement public des cultes d'Alsace-Lorraine",
  '7450': "Etablissement public administratif, cercle et foyer dans les armées",
  '7470': "Groupement de coopération sanitaire à gestion publique",
  '7490': "Autre personne morale de droit administratif",
  '8110': "Régime général de la Sécurité Sociale",
  '8120': "Régime spécial de Sécurité Sociale",
  '8130': "Institution de retraite complémentaire",
  '8140': "Mutualité sociale agricole",
  '8150': "Régime maladie des non-salariés non agricoles",
  '8160': "Régime vieillesse ne dépendant pas du régime général de la Sécurité Sociale",
  '8170': "Régime d'assurance chômage",
  '8190': "Autre régime de prévoyance sociale",
  '8210': "Mutuelle",
  '8250': "Assurance mutuelle agricole",
  '8290': "Autre organisme mutualiste",
  '8310': "Comité social économique d’entreprise",
  '8311': "Comité social économique d'établissement",
  '8410': "Syndicat de salariés",
  '8420': "Syndicat patronal",
  '8450': "Ordre professionnel ou assimilé",
  '8470': "Centre technique industriel ou comité professionnel du développement économique",
  '8490': "Autre organisme professionnel",
  '8510': "Institution de prévoyance",
  '8520': "Institution de retraite supplémentaire",
  '9110': "Syndicat de copropriété",
  '9150': "Association syndicale libre",
  '9210': "Association non déclarée",
  '9220': "Association déclarée",
  '9221': "Association déclarée d'insertion par l'économique",
  '9222': "Association intermédiaire",
  '9223': "Groupement d'employeurs",
  '9224': "Association d'avocats à responsabilité professionnelle individuelle",
  '9230': "Association déclarée, reconnue d'utilité publique",
  '9240': "Congrégation",
  '9260': "Association de droit local (Bas-Rhin, Haut-Rhin et Moselle)",
  '9300': "Fondation",
  '9900': "Autre personne morale de droit privé",
  '9970': "Groupement de coopération sanitaire à gestion privée",
}

const suggestSelect = (self, label) => {
  return (
    <div style={{ flex: 1, margin: "10px" }}>
      <StyledLabel>{label.split(' de la société').join('')} (5 caractères mini) *</StyledLabel>
      <Autocomplete2
        wrapperStyle={{ width: "100%" }}
        items={self.state.suggest || []}
        getItemValue={(item) => `${item.denomination} (${item.activite} - ${item.codeCommune})`}
        renderInput={(props) =>
          <div>
            <input className='test2' {...props} label={label} />
          </div>
        }
        renderItem={(item, isHighlighted) =>
          <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
            {`${item.denomination} (${item.activite} - ${item.codeCommune})`}
          </div>
        }
        value={self.state.test || ''}
        onChange={(e) => {
          self.setState({ test: e.target.value }, () => {
            if (self.state.test.length >= 5) {
              Api.suggest(self.state.test).then(data => {
                self.setState({ suggest: data })
              })
            }
          })
        }}
        onSelect={(e) => {
          console.log(e)
          const siren = self.state.suggest.filter(item => `${item.denomination} (${item.activite} - ${item.codeCommune})` === e)[0].siren
          console.log(siren)
          Api.find(siren).then((dataSociete) => {
            if (!dataSociete || dataSociete.result === 'error') {
              return self.setState({
                calmos: true,
                ["Voulez vous qu'on remplisse automatiquement le formulaire concernant votre société ?"]: "Non, vous rentrez vos informations"
              })
            }
            const getCity = commune => {
              if (!commune)
                return {}
              const arr = commune.split(' ')
              return {
                city: arr.filter((_, i) => i < arr.length - 1).join(' '),
                code: arr[arr.length - 1]
              }
            }
            // localStorage.setItem("Numéro de SIRET", dataSociete.fiche.siren)
            // localStorage.setItem("Nom de la société", dataSociete.fiche.denomination)
            // localStorage.setItem("Adresse de la société", (dataSociete.establishments[0] || {}).adresse)
            // localStorage.setItem("Code postal de la société", getCity((dataSociete.establishments[0] || {}).commune).code)
            // localStorage.setItem("Ville de la société", getCity((dataSociete.establishments[0] || {}).commune).city)
            // localStorage.setItem("Email de la société", dataSociete.contacts.emails[0] || '')
            // localStorage.setItem("Téléphone de la société", dataSociete.contacts.phones[0] || '')
            // localStorage.setItem("Num TVA Intracommunautaire", `FR${(12 + 3 * (dataSociete.fiche.siren % 97)) % 97}${dataSociete.fiche.siren}`)
            // localStorage.setItem("Préfixe de la société (SAS, SARL, EURL, Auto-entrepreneur, ...)", render_categorieJuridiqueUniteLegale[dataSociete.fiche.categorieJuridique])
            self.setState({
              dataSociete,
              ["Numéro de SIRET"]: dataSociete.fiche.siren,
              ["Nom de la société"]: dataSociete.fiche.denomination,
              ["Adresse de la société"]: (dataSociete.establishments[0] || {}).adresse,
              ["Code postal de la société"]: getCity((dataSociete.establishments[0] || {}).commune).code,
              ["Ville de la société"]: getCity((dataSociete.establishments[0] || {}).commune).city,
              ["Email de la société"]: dataSociete.contacts.emails[0] || '',
              ["Téléphone de la société"]: dataSociete.contacts.phones[0] || '',
              ["Num TVA Intracommunautaire"]: `FR${(12 + 3 * (dataSociete.fiche.siren % 97)) % 97}${dataSociete.fiche.siren}`,
              ["Préfixe de la société (SAS, SARL etc..)"]: render_categorieJuridiqueUniteLegale[dataSociete.fiche.categorieJuridique],
            })
          })
        }}
      />
    </div>
  )
}

const ButtonLinked1 = styled.button`
width: 100%;
background: linear-gradient(119.49deg, #68dbab 3.02%, #2fb8a7 111.32%);
padding: 8px 20px;
border: 1px solid #222;
color: #fff;
cursor: pointer;
font-size: 0.85rem;
text-transform: uppercase;
font-weight: 600;
border-radius: 7px;
`
const ButtonLinked2 = styled.button`
width: 100%;
background-color: #fff;
padding: 8px 20px;
border: 1px solid #222;
color: #000;
cursor: pointer;
font-size: 0.85rem;
text-transform: uppercase;
font-weight: 600;
border-radius: 7px;
`

const ButtonFinal = styled.button`
width: 100%;
height: 66px;
background: linear-gradient(119.49deg, #68dbab 3.02%, #2fb8a7 111.32%);
padding: 8px 20px;
border: 1px solid #222;
color: #fff;
cursor: pointer;
font-size: 0.85rem;
text-transform: uppercase;
font-weight: 600;
border-radius: 7px;
`


class ButtonLinked extends React.Component {
  render() {
    if (this.props.active) {
      return <div style={{ margin: "10px" }}><ButtonLinked1>{this.props.label}</ButtonLinked1></div>
    } else {
      return <div style={{ margin: "10px" }}><ButtonLinked2 onClick={() => this.props.onClick()} >{this.props.label}</ButtonLinked2></div>
    }
  }
}

const toTable1 = (values) => {
  return (
    <>
      <div className="container">
        <div style={{ flex: 2 }} ></div>
        <div style={{ flex: 10 }} >{values[0]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 10 }} >{values[1]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 1 }} >{values[9]}</div>
      </div>
      <div className="container">
        <div style={{ flex: 12 }} >{values[2]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 12 }} >{values[3]}</div>
      </div>
      <div className="container">
        <div style={{ flex: 25 }} >{values[4]}</div>
      </div>
      <div className="container">
        <div style={{ flex: 12 }} >{values[5]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 12 }} >{values[6]}</div>
      </div>
      <div className="container">
        <div style={{ flex: 12 }} >{values[7]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 12 }} >{values[8]}</div>
      </div>
    </>
  )
}

const toTable2 = (values) => {
  return (
    <>
      <div className="container">
        <div style={{ flex: 2 }} ></div>
        <div style={{ flex: 10 }} >{values[0]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 10 }} >{values[1]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 1 }} >{values[13]}</div>
      </div>
      <div className="container">
        <div style={{ flex: 12 }} >{values[2]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 12 }} >{values[3]}</div>
      </div>
      <div className="container">
        <div style={{ flex: 8 }} >{values[4]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 7 }} >{values[5]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 8 }} >{values[6]}</div>
      </div>
      <div className="container">
        <div style={{ flex: 8 }} >{values[7]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 7 }} >{values[8]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 8 }} >{values[9]}</div>
      </div>
      <div className="container">
        <div style={{ flex: 8 }} >{values[10]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 7 }} >{values[11]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 8 }} >{values[12]}</div>
      </div>
    </>
  )
}

const toTable3 = (values) => {
  return (
    <>
      <div className="container">
        <div style={{ flex: 2 }} ></div>
        <div style={{ flex: 10 }} >{values[0]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 10 }} >{values[1]}</div>
        <div style={{ flex: 2 }} ></div>
      </div>
      <div className="container">
        <div style={{ flex: 12 }} >{values[2]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 12 }} >{values[3]}</div>
      </div>
      <div className="container">
        <div style={{ flex: 12 }} >{values[4]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 12 }} >{values[5]}</div>
      </div>
      <div className="container">
        <div style={{ flex: 12 }} >{values[6]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 12 }} >{values[7]}</div>
      </div>
      <div className="container">
        <div style={{ flex: 12 }} >{values[8]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 12 }} >{values[9]}</div>
      </div>
    </>
  )
}

const toTable4 = (values) => {
  return (
    <>
      <div className="container">
        <div style={{ flex: 2 }} ></div>
        <div style={{ flex: 10 }} >{values[0]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 10 }} >{values[1]}</div>
        <div style={{ flex: 2 }} ></div>
      </div>
      <div className="container">
        <div style={{ flex: 5 }} >{values[2]}</div>
      </div>
      <div className="container">
        <div style={{ flex: 12 }} >{values[3]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 12 }} >{values[4]}</div>
      </div>
      <div className="container">
        <div style={{ flex: 12 }} >{values[5]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 12 }} >{values[6]}</div>
      </div>
      <div className="container">
        <div style={{ flex: 12 }} >{values[7]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 12 }} >{values[8]}</div>
      </div>
    </>
  )
}

const toTable5 = (values) => {
  return (
    <>
      <div className="container">
        <div style={{ flex: 2 }} ></div>
        <div style={{ flex: 10 }} >{values[0]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 10 }} >{values[1]}</div>
        <div style={{ flex: 2 }} ></div>
      </div>
      <div className="container">
        <div style={{ flex: 25 }} >{values[2]}</div>
      </div>
      <div className="container">
        <div style={{ flex: 25 }} >{values[3]}</div>
      </div>
      <div className="container">
        <div style={{ flex: 25 }} >{values[4]}</div>
      </div>
      <div className="container">
        <div style={{ flex: 25 }} >{values[5]}</div>
      </div>
    </>
  )
}

const toTableEnd = (values) => {
  return (
    <>
      <div className="container double-size">
        <div style={{ flex: 9 }} ></div>
        <div style={{ flex: 7 }} >{values[0]}</div>
        <div style={{ flex: 9 }} ></div>
      </div>
      <div className="container double-size">
        <div style={{ flex: 6 }} ></div>
        <div style={{ flex: 13 }} >{values[1]}</div>
        <div style={{ flex: 6 }} ></div>
      </div>
      <div className="container double-size">
        <div style={{ flex: 8 }} >{values[2]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 7 }} >{values[3]}</div>
        <div style={{ flex: 1 }} ></div>
        <div style={{ flex: 8 }} >{values[4]}</div>
      </div>
    </>
  )
}

const fromState = (state) => {
  const a = {
    "Prénom": "Votre_prenom",
    "Nom": "Votre_nom",
    "Adresse": "Votre_adresse",
    "Code postal": "Votre_code_postal",
    "Ville": "Votre_ville",
    "Email": "Votre_email",
    "Téléphone": "Votre_telephone",
    "Nom de la société": "Nom_de_la_societe",
    "Préfixe de la société (SAS, SARL etc..)": "Prefixe_de_la_societe",
    "Numéro de SIRET": "Numero_de_SIRET",
    "Adresse de la société": "Adresse_de_la_societe",
    "Code postal de la société": "Code_postal_de_la_societe",
    "Ville de la société": "Ville_de_la_societe",
    "Email de la société": "Email_de_la_societe",
    "Téléphone de la société": "Telephone_de_la_societe",
    "Num TVA Intracommunautaire": "Num_TVA_Intracommunautaire",
    "Nom/Prénom du Propriétaire du site": "Nom_Prenom_du_Proprietaire_du_site",
    "Adresse mail du Propriétaire du site": "Adresse_mail_du_Proprietaire_du_site",
    "Nom/Prénom du Directeur de Publication": "Nom_Prenom_du_Directeur_de_Publication",
    "Adresse mail du Directeur de Publication": "Adresse_mail_du_Directeur_de_Publication",
    "Nom/Prénom du Webmaster": "Nom_Prenom_du_Webmaster",
    "Adresse mail du Webmaster": "Adresse_mail_du_Webmaster",
    "Nom/Prénom Autre": "Nom_Prenom_Autre",
    "Adresse mail Autre": "Adresse_mail_Autre",
    "Nom de l'hébergeur": "Nom_de_l_hebergeur",
    "Capital Social de l'hébergeur": "Capital_Social_de_l_hebergeur",
    "Type de société de l'hébergeur": "Type_de_societe_de_l_hebergeur",
    "RCS de l'hébergeur": "RCS_de_l_hebergeur",
    "Code APE de l'hébergeur": "Code_APE_de_l_hebergeur",
    "TVA de l'hébergeur": "TVA_de_l_hebergeur",
    "Adresse de l'hébergeur": "Adresse_de_l_hebergeur",
    "Le lien vers votre politique RGPD": "Le_lien_vers_votre_politique_RGPD",
    "URL de votre site": "URL_de_votre_site",
    "Email où recevoir les mentions légales": "Email_ou_recevoir_les_mentions_legales",
    "J'accepte de recevoir mes mentions légales par mail": "J_accepte_de_recevoir_mes_mentions_legales_par_mail",
    "Test": "Test"
  }
  const json = {}
  for (let i in a) {
    json[a[i]] = state[i]
  }
  json["URL_de_votre_site"] = json["URL_de_votre_site"].split('www.').join('').split('https://').join('').split('http://').join('').split('/')[0]
  for (let i in json) {
    if (json[i] && typeof json[i] === 'string') {
      json[i] = json[i].split("[NOM DE DOMAINE DU SITE]").join(json["URL_de_votre_site"])
    }
  }

  return json
}

class Example extends Component {

  constructor(props) {
    super(props)
    this.state = {
      active: 1,
      ["Vous êtes (choisissez : Particulier ou Société)"]: "Particulier",
      ["Voulez vous qu'on remplisse automatiquement le formulaire concernant votre société ?"]: "Non, vous rentrez vos informations",
      ["Une seule personne s'occupe du site ?"]: "Oui",
      ["Voulez vous qu'on remplisse automatiquement le formulaire concernant votre hébergeur ?"]: "Oui",
      ["Avez vous une page RGPD ?"]: "Oui",
    }

    this.h = {
      "": {
        ["Nom de l'hébergeur"]: "",
        ["Capital Social de l'hébergeur"]: "",
        ["Type de société de l'hébergeur"]: "",
        ["RCS de l'hébergeur"]: "",
        ["Code APE de l'hébergeur"]: "",
        ["TVA de l'hébergeur"]: "",
        ["Adresse de l'hébergeur"]: ""
      },
      "Kinsta": {
        ["Nom de l'hébergeur"]: "Kinsta",
        ["Capital Social de l'hébergeur"]: "1000000 de parts",
        ["Type de société de l'hébergeur"]: "Foreign Business Corporation",
        ["RCS de l'hébergeur"]: "11161375",
        ["Code APE de l'hébergeur"]: "-",
        ["TVA de l'hébergeur"]: "-",
        ["Adresse de l'hébergeur"]: "8605 Santa Monica Blvd, West Hollywood, CA 90069"
      },
      "OVH": {
        ["Nom de l'hébergeur"]: "OVH",
        ["Capital Social de l'hébergeur"]: "10 174 560 Euros",
        ["Type de société de l'hébergeur"]: "SAS",
        ["RCS de l'hébergeur"]: "Lille Métropole 424 761 419 00045",
        ["Code APE de l'hébergeur"]: "2620Z",
        ["TVA de l'hébergeur"]: "FR 22 424 761 419",
        ["Adresse de l'hébergeur"]: "2 rue Kellermann - 59100 Roubaix - France"
      },
      "O2Switch": {
        ["Nom de l'hébergeur"]: "O2Switch",
        ["Capital Social de l'hébergeur"]: "100 000 Euros",
        ["Type de société de l'hébergeur"]: "SASU",
        ["RCS de l'hébergeur"]: "Clermont-Ferrand B 510909807",
        ["Code APE de l'hébergeur"]: "6311Z",
        ["TVA de l'hébergeur"]: "FR 35 510 909 807",
        ["Adresse de l'hébergeur"]: "222-224 Boulevard Gustave Flaubert - 63000 Clermont-Ferrand - France"
      },
      "Infomaniak": {
        ["Nom de l'hébergeur"]: "Infomaniak",
        ["Capital Social de l'hébergeur"]: "102000 CHF",
        ["Type de société de l'hébergeur"]: "Network SA",
        ["RCS de l'hébergeur"]: "CH-660.0.059.996-1",
        ["Code APE de l'hébergeur"]: "-",
        ["TVA de l'hébergeur"]: "CHE-103.167.648",
        ["Adresse de l'hébergeur"]: "Rue Eugène Marziano 25 - 1227 Les Acacias (GE) - Suisse"
      },
      "Gandi": {
        ["Nom de l'hébergeur"]: "Gandi",
        ["Capital Social de l'hébergeur"]: "630 000 Euros",
        ["Type de société de l'hébergeur"]: "SASU",
        ["RCS de l'hébergeur"]: "Paris B 423 093 459",
        ["Code APE de l'hébergeur"]: "6311Z",
        ["TVA de l'hébergeur"]: "FR 81 423 093 459",
        ["Adresse de l'hébergeur"]: "63-65 boulevard Masséna - 75013 Paris - France"
      },
      "Ionos": {
        ["Nom de l'hébergeur"]: "Ionos",
        ["Capital Social de l'hébergeur"]: "100 000 Euros",
        ["Type de société de l'hébergeur"]: "EURL",
        ["RCS de l'hébergeur"]: "Sarreguemines B 431 303 775",
        ["Code APE de l'hébergeur"]: "6311Z",
        ["TVA de l'hébergeur"]: "FR 13 431 303 775",
        ["Adresse de l'hébergeur"]: "7 place de la Gare - 57200 Sarreguemines - France"
      },
      "Amen": {
        ["Nom de l'hébergeur"]: "Amen",
        ["Capital Social de l'hébergeur"]: "37 000 Euros",
        ["Type de société de l'hébergeur"]: "SASU",
        ["RCS de l'hébergeur"]: "Paris B 421 527 797",
        ["Code APE de l'hébergeur"]: "6312Z",
        ["TVA de l'hébergeur"]: "FR 29 421 527 797",
        ["Adresse de l'hébergeur"]: "12-14, Rond-Point des Champs-Élysées - 75008 Paris - France"
      },
      "PlanetHoster": {
        ["Nom de l'hébergeur"]: "PlanetHoster",
        ["Capital Social de l'hébergeur"]: "-",
        ["Type de société de l'hébergeur"]: "Company Type For Profit",
        ["RCS de l'hébergeur"]: "-",
        ["Code APE de l'hébergeur"]: "-",
        ["TVA de l'hébergeur"]: "-",
        ["Adresse de l'hébergeur"]: "4416 Louis-B.-Mayer - Laval, Québec"
      },
      "LWS": {
        ["Nom de l'hébergeur"]: "LWS",
        ["Capital Social de l'hébergeur"]: "500 000 Euros",
        ["Type de société de l'hébergeur"]: "SAS",
        ["RCS de l'hébergeur"]: "RCS Paris B 851 993 683 00024",
        ["Code APE de l'hébergeur"]: "6311Z",
        ["TVA de l'hébergeur"]: "FR 21 851 993 683",
        ["Adresse de l'hébergeur"]: "10, RUE PENTHIEVRE - 75838 Paris - France"
      },
    }
  }

  random() {
    const randomElem = (...arr) => arr[Math.floor(arr.length * Math.random())]
    const location = randomElem(...locations)
    const a = {
      "Prénom": randomElem(...firstnames),
      "Nom": randomElem(...lastnames),
      "Adresse": location[0],
      "Code postal": location[1],
      "Ville": location[2],
      "Email": randomElem('contact@[NOM DE DOMAINE DU SITE]'),
      "Téléphone": randomElem(...nums),
      "Nom de la société": randomElem('Nettoyage Hyper', 'Fan Mout', 'La Galerie Neo'),
      "Préfixe de la société (SAS, SARL etc..)": randomElem('SAS', 'EURL', 'SASU'),
      "Numéro de SIRET": randomElem('98765432100012', null),
      "Adresse de la société": location[0],
      "Code postal de la société": location[1],
      "Ville de la société": location[2],
      "Email de la société": randomElem('contact@[NOM DE DOMAINE DU SITE]'),
      "Téléphone de la société": randomElem(...nums),
      "Num TVA Intracommunautaire": randomElem('FR35987654321'),
      "Nom/Prénom du Propriétaire du site": randomElem(...firstnames) + ' ' + randomElem(...lastnames),
      "Adresse mail du Propriétaire du site": randomElem('contact@[NOM DE DOMAINE DU SITE]'),
      "Nom/Prénom du Directeur de Publication": randomElem(...firstnames) + ' ' + randomElem(...lastnames),
      "Adresse mail du Directeur de Publication": randomElem('contact@[NOM DE DOMAINE DU SITE]'),
      "Nom/Prénom du Webmaster": randomElem(...firstnames) + ' ' + randomElem(...lastnames),
      "Adresse mail du Webmaster": randomElem('contact@[NOM DE DOMAINE DU SITE]'),
      "Nom/Prénom Autre": randomElem(...firstnames) + ' ' + randomElem(...lastnames),
      "Adresse mail Autre": randomElem('contact@[NOM DE DOMAINE DU SITE]'),
      ...randomElem(this.h['OVH'], this.h['O2Switch'], this.h['Ionos']),
      "Vous êtes (choisissez : Particulier ou Société)": "Société",
      "Voulez vous qu'on remplisse automatiquement le formulaire concernant votre hébergeur ?": "Non",
      "Test": "Oui",
    }
    if (a["Numéro de SIRET"] === null) {
      a["Adresse de la société"] = null
      a["Code postal de la société"] = null
      a["Ville de la société"] = null
      a["Email de la société"] = null
      a["Téléphone de la société"] = null
      a["Num TVA Intracommunautaire"] = null
      a["Vous êtes (choisissez : Particulier ou Société)"] = "Particulier"
    }
    this.setState({ ...a })
  }

  render() {
    return (
      <div style={{ padding: "100px" }}>
        {/* <div style={{ margin: "100px", padding: "100px" }}>
          <ReactBubblyEffectButton text="Click here" color='#fff' bgColor='#ff0081' />
        </div> */}
        <MultiStepForm activeStep={this.state.active} accentColor={'#68DBAB'}>
          <Step label="Informations personnelles">
            <h3 style={{ textAlign: 'center' }}>Informations personnelles</h3>
            {
              this.state.calmos && (
                <div className="container">
                  <p style={{ color: "red" }}>Veuillez envoyer le formulaire avant de redemander les informations liées aux entreprises</p>
                </div>
              )
            }

            {this.state["Vous êtes (choisissez : Particulier ou Société)"] === "Particulier" &&
              toTable1([
                <ButtonLinked label={"Je suis un particulier"} active />,
                <ButtonLinked label={"Je suis une entreprise"} onClick={() => this.setState({
                  ["Vous êtes (choisissez : Particulier ou Société)"]: "Société",
                  Prefixe_de_la_societe: null,
                  Numero_de_SIRET: null,
                  Adresse_de_la_societe: null,
                  Code_postal_de_la_societe: null,
                  Ville_de_la_societe: null,
                  Email_de_la_societe: null,
                  Telephone_de_la_societe: null,
                  Num_TVA_Intracommunautaire: null,
                })} />,
                simpleTextView(this, "Prénom"),
                simpleTextView(this, "Nom"),
                simpleTextView(this, "Adresse"),
                simpleTextView(this, "Code postal"),
                simpleTextView(this, "Ville"),
                simpleTextView(this, "Email"),
                simpleTextView(this, "Téléphone"),
                <div style={{ cursor: "pointer", paddingTop: "10px" }} onClick={() => this.random()}><img src={'/shuffle.png'} /></div>,
              ])
            }
            {this.state["Vous êtes (choisissez : Particulier ou Société)"] === "Société" && (this.state["Voulez vous qu'on remplisse automatiquement le formulaire concernant votre société ?"] === "Non, vous rentrez vos informations" || this.state.dataSociete) &&
              toTable2([
                <ButtonLinked label={"Je suis un particulier"} onClick={() => this.setState({ ["Vous êtes (choisissez : Particulier ou Société)"]: "Particulier" })} />,
                <ButtonLinked label={"Je suis une entreprise"} active />,
                <ButtonLinked label={"Remplissage manuel"} active />,
                <ButtonLinked label={"Mode automatique"} onClick={() => this.setState({ ["Voulez vous qu'on remplisse automatiquement le formulaire concernant votre société ?"]: "Oui, vous pouvez corriger après" })} />,
                simpleTextView(this, "Nom de la société"),
                simpleTextView(this, "Préfixe de la société (SAS, SARL etc..)"),
                simpleTextView(this, "Numéro de SIRET"),
                simpleTextView(this, "Adresse de la société"),
                simpleTextView(this, "Code postal de la société"),
                simpleTextView(this, "Ville de la société"),
                simpleTextView(this, "Email de la société"),
                simpleTextView(this, "Téléphone de la société"),
                simpleTextView(this, "Num TVA Intracommunautaire"),
                <div style={{ cursor: "pointer", paddingTop: "10px" }} onClick={() => this.random()}><img src={'/shuffle.png'} /></div>,
              ])
            }
            {!this.state.dataSociete && this.state["Vous êtes (choisissez : Particulier ou Société)"] === "Société" && this.state["Voulez vous qu'on remplisse automatiquement le formulaire concernant votre société ?"] === "Oui, vous pouvez corriger après" &&
              toTable2([
                <ButtonLinked label={"Je suis un particulier"} onClick={() => this.setState({ ["Vous êtes (choisissez : Particulier ou Société)"]: "Particulier" })} />,
                <ButtonLinked label={"Je suis une entreprise"} active />,
                <ButtonLinked label={"Remplissage manuel"} onClick={() => this.setState({ ["Voulez vous qu'on remplisse automatiquement le formulaire concernant votre société ?"]: "Non, vous rentrez vos informations" })} />,
                <ButtonLinked label={"Mode automatique"} active />,
                suggestSelect(this, "Nom de la société"),
                simpleTextView(this, "Préfixe de la société (SAS, SARL etc..)"),
                simpleTextView(this, "Numéro de SIRET"),
                simpleTextView(this, "Adresse de la société"),
                simpleTextView(this, "Code postal de la société"),
                simpleTextView(this, "Ville de la société"),
                simpleTextView(this, "Email de la société"),
                simpleTextView(this, "Téléphone de la société"),
                simpleTextView(this, "Num TVA Intracommunautaire"),
              ])
            }
          </Step>
          <Step label="Responsable du site">
            <h3 style={{ textAlign: 'center' }}>Responsable du site</h3>
            {this.state["Une seule personne s'occupe du site ?"] === "Oui" &&
              toTable1([
                <ButtonLinked label={"Je m'occupe seul du site"} active />,
                <ButtonLinked label={"Plusieurs personnes s'occupent du site"} onClick={() => this.setState({ ["Une seule personne s'occupe du site ?"]: "Non" })} />,
                simpleTextView(this, "Prénom"),
                simpleTextView(this, "Nom"),
                simpleTextView(this, "Adresse"),
                simpleTextView(this, "Code postal"),
                simpleTextView(this, "Ville"),
                simpleTextView(this, "Email"),
                simpleTextView(this, "Téléphone"),
              ])
            }
            {this.state["Une seule personne s'occupe du site ?"] === "Non" &&
              toTable3([
                <ButtonLinked label={"Je m'occupe seul du site"} onClick={() => this.setState({ ["Une seule personne s'occupe du site ?"]: "Oui" })} />,
                <ButtonLinked label={"Plusieurs personnes s'occupent du site"} active />,
                simpleTextView(this, "Nom/Prénom du Propriétaire du site"),
                simpleTextView(this, "Adresse mail du Propriétaire du site"),
                simpleTextView(this, "Nom/Prénom du Directeur de Publication"),
                simpleTextView(this, "Adresse mail du Directeur de Publication"),
                simpleTextView(this, "Nom/Prénom du Webmaster"),
                simpleTextView(this, "Adresse mail du Webmaster"),
                simpleTextView(this, "Nom/Prénom Autre"),
                simpleTextView(this, "Adresse mail Autre")
              ])
            }
          </Step>
          <Step label="Hébergement">
            <h3 style={{ textAlign: 'center' }}>Hébergement</h3>
            {this.state["Voulez vous qu'on remplisse automatiquement le formulaire concernant votre hébergeur ?"] === "Oui" &&
              toTable4([
                <ButtonLinked label={"Remplissage manuel"} onClick={() => this.setState({ ["Voulez vous qu'on remplisse automatiquement le formulaire concernant votre hébergeur ?"]: "Non" })} />,
                <ButtonLinked label={"Mode automatique"} active />,
                simpleSelect(this, "Hébergeur", [
                  "",
                  "Kinsta",
                  "OVH",
                  "O2Switch",
                  "Infomaniak",
                  "Gandi",
                  "Ionos",
                  "Amen",
                  "PlanetHoster",
                  "LWS",
                  "Autre",
                ], () => {
                  this.setState({
                    ["Nom de l'hébergeur"]: this.state["Hébergeur"],
                    ...this.h[this.state["Hébergeur"]],
                    ["Voulez vous qu'on remplisse automatiquement le formulaire concernant votre hébergeur ?"]: "Non"
                  })
                }),
                simpleTextView(this, "Capital Social de l'hébergeur"),
                simpleTextView(this, "Type de société de l'hébergeur"),
                simpleTextView(this, "RCS de l'hébergeur"),
                simpleTextView(this, "Code APE de l'hébergeur"),
                simpleTextView(this, "TVA de l'hébergeur"),
                simpleTextView(this, "Adresse de l'hébergeur")
              ])
            }
            {this.state["Voulez vous qu'on remplisse automatiquement le formulaire concernant votre hébergeur ?"] === "Non" &&
              toTable4([
                <ButtonLinked label={"Remplissage manuel"} active />,
                <ButtonLinked label={"Mode automatique"} onClick={() => this.setState({ ["Voulez vous qu'on remplisse automatiquement le formulaire concernant votre hébergeur ?"]: "Oui" })} />,
                simpleTextView(this, "Nom de l'hébergeur"),
                simpleTextView(this, "Capital Social de l'hébergeur"),
                simpleTextView(this, "Type de société de l'hébergeur"),
                simpleTextView(this, "RCS de l'hébergeur"),
                simpleTextView(this, "Code APE de l'hébergeur"),
                simpleTextView(this, "TVA de l'hébergeur"),
                simpleTextView(this, "Adresse de l'hébergeur")
              ])
            }
          </Step>
          <Step label="RGPD">
            <h3 style={{ textAlign: 'center' }}>RGPD</h3>
            {this.state["Avez vous une page RGPD ?"] === "Oui" &&
              toTable5([
                <ButtonLinked label={"J'ai une page RGPD"} active />,
                <ButtonLinked label={"Je n'ai pas de page RGPD"} onClick={() => this.setState({ ["Avez vous une page RGPD ?"]: "Non" })} />,
                simpleTextView(this, "Le lien vers votre politique RGPD"),
                simpleTextView(this, "URL de votre site"),
                simpleTextView(this, "Email où recevoir les mentions légales"),
                simpleCheckbox(this, "J'accepte de recevoir mes mentions légales par mail"),
              ])
            }
            {this.state["Avez vous une page RGPD ?"] === "Non" &&
              toTable5([
                <ButtonLinked label={"J'ai une page RGPD"} onClick={() => this.setState({ ["Avez vous une page RGPD ?"]: "Oui" })} />,
                <ButtonLinked label={"Je n'ai pas de page RGPD"} active />,
                simpleTextView(this, "Le lien vers votre politique RGPD", true),
                simpleTextView(this, "URL de votre site"),
                simpleTextView(this, "Email où recevoir les mentions légales"),
                simpleCheckbox(this, "J'accepte de recevoir mes mentions légales par mail"),
              ])
            }
          </Step>
          <Step label="Fin">
            {this.state.json ? (
              <>
                <Confetti
                  width={window.innerWidth}
                  height={window.innerHeight}
                  recycle={false}
                />
                {toTableEnd([
                  <div style={{ width: "150px", margin: "auto" }}>
                    <Lottie
                      src="https://assets3.lottiefiles.com/packages/lf20_0589tdzw.json"
                      animationData={lottieJson}
                      speed={0.75} loop controls autoPlay play
                      style={{ width: "150px", height: "150px" }}
                    />
                  </div>,
                  <div><div><h3 style={{ textAlign: 'center' }}>Vos mentions légales sont prêtes !</h3></div><div><h3 style={{ textAlign: 'center' }}>Merci pour votre confiance</h3></div></div>,
                  //<ButtonFinal onClick={() => navigator.clipboard.writeText(this.state.json.str)} >Copier (Visuel)</ButtonFinal>,
                  // <ButtonFinal onClick={() => navigator.clipboard.writeText(this.state.json.html)} >Copier (HTML)</ButtonFinal>,
                  // <ButtonFinal onClick={() => window.open(this.state.json.link, '_blank')} >Voir les Mentions</ButtonFinal>,
                  <ReactBubblyEffectButton text="Copier (Visuel)" color='#fff' bgColor='#68DBAB' onClick={() => navigator.clipboard.writeText(this.state.json.str)} />,
                  <ReactBubblyEffectButton text="Copier (HTML)" color='#fff' bgColor='#68DBAB' onClick={() => {
                    console.log("json", this.state.json)
                    navigator.clipboard.writeText(this.state.json.html)
                  }} />,
                  <ReactBubblyEffectButton text="Voir les Mentions" color='#fff' bgColor='#68DBAB' onClick={() => window.open(this.state.json.link, '_blank')} />
                ])}
              </>
            ) : (
              toTableEnd([
                <div style={{ width: "150px", margin: "auto" }}>
                  <Lottie
                    src="https://assets3.lottiefiles.com/packages/lf20_0589tdzw.json"
                    animationData={lottieJson}
                    speed={0.75} loop controls autoPlay play
                    style={{ width: "150px", height: "150px" }}
                  />
                </div>,
                <div><div><h3 style={{ textAlign: 'center' }}>En attente de vos mentions légales</h3></div></div>,
                <div></div>,
                <div></div>,
                <div></div>,
              ])
            )}
          </Step>
        </MultiStepForm>
        <div style={{ marginTop: "10px" }}>
          <div className="container">
            <div style={{ flex: 4 }} >
              {
                this.state.active !== 5 && this.state.active !== 1 && (
                  <ButtonLinked1
                    onClick={() => this.setState({ active: this.state.active - 1 })}
                    style={{ float: 'left' }}
                  >
                    Précédent
                  </ButtonLinked1>
                )
              }
            </div>
            <div style={{ flex: 17 }} ></div>
            <div style={{ flex: 4 }} >
              {
                this.state.active !== 5 && (
                  <ButtonLinked1
                    onClick={() => {
                      this.setState({ submit: true }, () => {
                        const missing = {}
                        const mandatory = (() => {
                          if (this.state.active === 1) {
                            return this.state["Vous êtes (choisissez : Particulier ou Société)"] === "Particulier" ? mandatory1_1 : mandatory1_2
                          }
                          if (this.state.active === 2) {
                            return this.state["Une seule personne s'occupe du site ?"] === "Oui" ? mandatory2_1 : mandatory2_2
                          }
                          if (this.state.active === 3) {
                            return mandatory3
                          }
                          if (this.state.active === 4) {
                            return mandatory4
                          }
                        })()
                        for (let i in mandatory) {
                          if (!this.state[mandatory[i]]) {
                            missing[mandatory[i]] = true
                          }
                        }
                        console.log(missing)
                        this.setState({ missing: { ...missing }, error: false }, () => {
                          if (Object.keys(missing).length === 0) {
                            this.setState({ active: this.state.active + 1, submit: false }, () => {
                              if (this.state.active === 5) {
                                Api.postForm1(fromState(this.state)).then((json) => {
                                  // this.setState({ hubspotErr: true })
                                  if (json.result === 'success') {
                                    this.setState({ json })
                                    // window.location = '/success'
                                  } else {
                                    this.setState({ error: true, submit: false })

                                  }
                                })
                              }
                            })
                          } else {
                            this.setState({ submit: false })
                          }
                        })
                      })
                    }}
                    style={{ float: 'right' }}
                  >
                    {this.state.active !== 4 ? 'Suivant' : 'Envoyer'}
                  </ButtonLinked1>
                )
              }
            </div>
          </div>
        </div>
      </div >
    )
  }
}
export default Example;
